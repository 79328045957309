import React from 'react';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';

export default class Map extends React.Component {
    constructor(props) {
        super(props);
        this.initMap = this.initMap.bind(this);
        this.state = {};
        }

    initMap() {
        console.log("InitMAP");
        this.setState({
            _mapInit: true
        });
        var latLng = new window.google.maps.LatLng(this.props.siteData.location1.split(',')[0], this.props.siteData.location1.split(',')[1]);
        var latLng2 = new window.google.maps.LatLng(this.props.siteData.location2.split(',')[0], this.props.siteData.location2.split(',')[1]);

        var map = new window.google.maps.Map(this.GoogleMap, {
            zoom: 16,
            center: latLng,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            gestureHandling: "gestureHandling",
        });


        var marker = new window.google.maps.Marker({
            position: latLng,
            map: map,
        });

        var map1 = new window.google.maps.Map(this.GoogleMap1, {
            zoom: 16,
            center: latLng2,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            gestureHandling: "gestureHandling",
        });


        var marker1 = new window.google.maps.Marker({
            position: latLng2,
            map: map1,
        });
    }

     componentDidMount() {
        if (this.props.mapinit && !this.state._mapInit && this.props.siteData) {
            this.initMap();
        }
    }

    componentDidUpdate() {
        if (this.props.mapinit && !this.state._mapInit && this.props.siteData) {
            this.initMap();
        }
    }

    render() {
        return (
            <div>
                <Container fluid>
                    <Row className="first">
                        <Col xl={6} lg={6} md={12} sm={12}>
                            {
                                this.props.mapinit ?
                                    <div className="map" ref={(input) => { this.GoogleMap = input; }}>
                                        <Container>
                                            <Row>

                                            </Row>
                                        </Container>
                                    </div>
                                    : null
                            }
                        </Col>

                        <Col xl={6} lg={6} md={12} sm={12}>
                            {
                                this.props.mapinit ?
                                    <div className="map1" ref={(input) => { this.GoogleMap1 = input; }}>

                                    </div>
                                    : null
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
