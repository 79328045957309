
import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import socketIO from 'socket.io-client';
import { createBrowserHistory } from 'history';

import 'bootstrap/dist/css/bootstrap.min.css';
import './main.css';
import 'font-awesome/css/font-awesome.min.css';

import Home from './Home';
import About from './about';
import GoogleMap from './googleMapScript';
import Production from './Production';
import Contact from './Contact';
import Photogallery from './photo';
import Offer from './offer';

const history = createBrowserHistory();

export default class Ruter extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.callback);
    this.callback = this.callback.bind(this);
    window.googleMapsCallback = this.callback;
    this.state = {};
  }

  callback() {
    this.setState({ mapinit: true });
  }

  componentDidMount() {
    const unlisten = history.listen((location, action) => {
      //this.props.handleMenu(null);
      window.scrollTo(0, 0);
  });

    let socket = socketIO('https://socket.mdmstankom.com');
    console.log(socket);
    this.setState({
      socketIOClient: socket
    });

    socket.emit("siteData", {});

    socket.on('siteData', (data) => {
      console.log(data);
      this.setState({
        siteData: data
      });
    });  
  }
  
  render() {
    return (
      <div>
      <GoogleMap API_KEY="AIzaSyByPzC2VWMbbvAyR3MgBZktkV3gc-nc3rY" />
      <Router>
        <Switch>
          <Route exact path="/" render = {(...renderProps) => (<Home {...renderProps} {...this.state} ></Home>)} />
          <Route exact path="/about" render = {(...renderProps) => (<About {...renderProps} {...this.state} ></About>)} />
          <Route exact path="/production" render = {(...renderProps) => (<Production {...renderProps} {...this.state} ></Production>)} />
          <Route exact path="/contact" render = {(...renderProps) => (<Contact {...renderProps} {...this.state} ></Contact>)} />
          <Route exact path="/gallery" render = {(...renderProps) => (<Photogallery {...renderProps} {...this.state} ></Photogallery>)} />
          <Route exact path="/offer" render = {(...renderProps) => (<Offer {...renderProps} {...this.state} ></Offer>)} />
          <Route exact path="/ponuda/:alias" render = {(...renderProps) => (<Offer {...renderProps} {...this.state} ></Offer>)} />
          <Route exact path="/proizvodnja/:alias" render = {(...renderProps) => (<Production {...renderProps} {...this.state} ></Production>)} />

        </Switch>
      </Router>
      </div>
    )
  }
};
