import React from 'react';
import BreadBack from './images/breadcrump-bg.png';
import { Link } from 'react-router-dom';
import Gallery from './Gallery';
import Offerslider from './Offer-slider';
import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators

} from 'reactstrap';
import Map from './Map';
import MyNavbar from './Navigation';
import Footer from './Footer';
export default class Offer extends React.Component {
    constructor(props) {
        super(props);
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);

        this.state = {
            activeIndex: 0,
            gallery: []
        };
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.gallery.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.gallery.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    componentDidMount() {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

        if (prevProps[0].match.params.alias !== this.props[0].match.params.alias){
            window.scrollTo(0, 0);
            this.props.socketIOClient.emit('fetchProduct', { alias: this.props[0].match.params.alias });
        }
    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });





        this.props.socketIOClient.on('fetchProduct', (data) => {
            console.log(data);

            this.setState({
                ...data
            });
        });


        this.props.socketIOClient.emit('fetchProduct', { alias: this.props[0].match.params.alias });
    }

    selectGalelry(id, idx){
        let gallery = [];
        let activeIndex = 0;

        for(let i=0;i<this.state.columns.length;i++){
            if (this.state.columns[i].columnType == 'image' && this.state.columns[i].id == id){
                if (i<idx)
                activeIndex++;
                gallery.push( {image: this.state.columns[i].value, title: this.state.columns[i].title});
            }
        }
        this.setState({
            gallery: gallery,
            opened: true, 
            activeIndex
        })
    }

    render() {

        let activeIndex = this.state.activeIndex;
        const slides2 = this.state.gallery.map((item, idx) => {
            return (
                <CarouselItem className="bg"
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={idx}>
                    <img src={'https://mdmstankom.com/' + item.image.image} className="img-fluid" alt={'Galerija ' + idx} />
                    <div className="carousel-caption">

                        <div className="container">
                            <div className="row d-flex justify-content-start">
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    {item.title}
                                </div>
                            </div>
                        </div>

                    </div>
                </CarouselItem>
            )
        });

        return (
            <div>
                <MyNavbar {...this.props}></MyNavbar>
                <div className="production2">
                    <Container fluid>
                        <Row>
                            <img src={BreadBack} className="img-fluid breadImg" />
                        </Row>
                        <Container>
                            <Row>
                                <Col xl={6}>
                                    <h1>{this.props[0].location.pathname.indexOf('ponuda') !== -1 ? 'Ponuda' : 'Proizvodnja'}</h1>
                                </Col>
                                <Col xl={6} className="d-flex justify-content-end">
                                    <NavItem>
                                        <Link to="/" className="nav-link">Početna</Link>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#">{this.props[0].location.pathname.indexOf('ponuda') !== -1 ? 'Ponuda' : 'Proizvodnja'}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#">{this.state.title}</NavLink>
                                    </NavItem>

                                </Col>
                            </Row>
                        </Container>
                    </Container>

                </div>
                <div className="offer-wrapper">
                    <Container>
                        <Row>
                            {
                                this.state.columns && this.state.columns.map((column, idx) => {
                                    if (column.columnType === 'image') {
                                        return (
                                            <Col lg={{ size: column.desktopColumnWidth }} xs={{ size: column.mobileColumnWidth }} key={idx}>
                                                <img onClick={() => this.selectGalelry(column.id, idx)} src={'https://mdmstankom.com/' + column.value.thumb} />
                                            </Col>
                                        )
                                    } else {
                                        return (
                                            <Col lg={{ size: column.desktopColumnWidth }} xs={{ size: column.mobileColumnWidth }} key={idx} dangerouslySetInnerHTML={{ __html: column.value }}>

                                            </Col>
                                        )
                                    }
                                })
                            }


                        </Row>
                    </Container>
                </div>

                {this.state.opened ?
                    <div className="lightbox">
                        <Container>
                            <Row>
                                <i className="fa fa-times" aria-hidden="true" onClick={() => this.setState({ opened: false })}></i>

                                <Carousel className="bg"
                                    activeIndex={activeIndex}
                                    next={this.next}
                                    previous={this.previous}
                                >
                                    <CarouselIndicators items={this.state.gallery} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                                    {slides2}
                                    <i className="fa fa-angle-left" onClick={this.previous}></i>
                                    <i className="fa fa-angle-right" onClick={this.next}></i>

                                </Carousel>
                            </Row>
                        </Container>
                    </div> : null
                }
                <Offerslider></Offerslider>

                <Map {...this.props}></Map>
                <Footer {...this.props}></Footer>
            </div>
        );
    }
}
