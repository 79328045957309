import React from 'react';
import BreadBack from './images/breadcrump-bg.png';
import Building from './images/Stankom-building.png';
import Building2 from './images/Stankom-building2.png';
import {Link} from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  NavItem,
  NavLink
} from 'reactstrap';
import Map from './Map';
import MyNavbar from './Navigation';
import Footer from './Footer';
import worker from './images/workers.png';
import diamond from './images/diamond.png';
import letter from './images/letter.png';
import quality from './images/quality.png';
import check from './images/check.png';
import support from './images/support.png';
export default class About extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
}
  componentDidUpdate() {
  }

  render() {
 
    return (
      <div>
        <MyNavbar {...this.props}></MyNavbar>
        <div className="aboutUs">
          <Container fluid>
            <Row>
              <img src={BreadBack} className="img-fluid" />
            </Row>
            <Container>
              <Row>
                <Col xl={6}>
                  <h1>O nama</h1>
                </Col>
                <Col xl={6} className="d-flex justify-content-end">
                  <NavItem>
                    <Link to = "/" className = "nav-link">Početna</Link>
                  </NavItem>
                  <NavItem>
                    <NavLink href="#">O nama</NavLink>
                  </NavItem>
                </Col>
              </Row>
            </Container>
          </Container>
          <div className="aboutText">
            <Container>
              <Row>
                <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                  <h6>15 GODINA ISKUSTVA</h6>
                  <h2>O kompaniji MDM-STANKOM</h2>
                  <p>Naša firma je počela sa radom 1976.godine, kao SZR sa sjedištem
                  u Bijeljini. Godine 1978. prenosimo sjedište u Republiku Sloveniju,
                  tačnije u SO Tolmin, gdje smo radili sve do 1987.godine. Nakon
                  toga smo prijavili sjedište firme u Brčkom, gdje se i sada
                  nalazimo, u ulici Bijeljinska br.71. Na početku poslovanja smo
                  zapošljavali 7 stalnih radnika i po potrebi do 30 radnika preko
                  tadašnjih omladinskih zadruga i ugovora o djelu. </p>
                </Col>

                <Col xl={5} lg={6} md={12} sm={12} xs={12} className="offset-xl-1">
                  <img src={Building} className="img-fluid building"></img>
                </Col>
              </Row>
              <Row>
              <Col xl={5} lg={6} md={12} sm={12} xs={12}>
                  <img src={Building2} className="img-fluid building"></img>
                </Col>

                <Col xl={6} className = "offset-xl-1">
                  <p className = "light-silver">Vremenom je se broj zaposlenih povećavao, tako da danas zapošljavamo 25 redovno zaposlenih radnika. Od 1995.godine
                   uglavnom se bavimo trgovinom građevinskim materijalom.</p>
                  <p>Poslednjih godina naša firma je pored trgovine gradjevinskim materijalom, ali prije svega crnom i obojenom metalurgijom,
                  zbog potražnje na tržištu i odgovoru na današnju ponudu i tražnju je krenula na put proizvodnje. Ceo poslovni sistem firme
                  zasnovan je na visokoj stručnosti zaposlenih, i spremnosti da se u svakom trenutku odgovori na specifične zahteve kupaca.</p>
                  </Col>

                <Col xl = {12}>
                  <p>Naši proizvodi su sa karakterom i stilom koji se nadaleko prepoznaju i cijene, što pokazuju i brojne reference u užem i širem
                  okruženju i prisutnost preduzeća u mnogim segmentima. Na našem putu nas prate načela kvaliteta, tačnosti i trajnog rješenja.
                  Nase proizvode odlikuju kvalitet, čvrstoća, kvalitet, brzina, ekoloski standard i dr. Pored proizvoda u mogucnosti smo da Vam
                  ponudimo i saradnju u pogledu dorade, obrade odredjenog proizvoda kao i uslugu.</p>

                  <h3>Proizvodi:</h3>
                  <p className = "paragraf-proizvodi">1. &nbsp;&nbsp;&nbsp;POC I PVC  Univerzal pletivo svih dimenzija</p>
                  <p className = "paragraf-proizvodi">2. &nbsp;&nbsp;&nbsp;Grifovana Pletiva svih vrsta i dimenzija</p>
                  <p className = "paragraf-proizvodi"> 3. &nbsp;&nbsp;&nbsp;POC i PVC Kanati svih vrsta, dimenzija i boja (EKO PROIZVOD) kao i prateći program</p>
                  <p className = "paragraf-proizvodi">4. &nbsp;&nbsp;&nbsp;POC I PVC Paneli svih vrsta, dimenzija i boja (EKO PROIZVOD) kao i prateći program</p>
                  <p className = "paragraf-proizvodi">5.&nbsp;&nbsp;&nbsp;Gravirano željezo ili Profili svih dimenzija i gravura</p>
                  <p className = "paragraf-proizvodi">Posebno mjesto u lepezi nasih proizvoda čine proizvodi iz grane poljoprivrede (za svinjske farme) a to su :</p>
                  <p className = "paragraf-proizvodi">1.&nbsp;&nbsp;&nbsp; PRASILIŠTA</p>
                  <p className = "paragraf-proizvodi">2. &nbsp;&nbsp;&nbsp;PRIPUSTILIŠTA  </p>

                  <p className = "paragraf-proizvodi">Mogucnosti saradnje u pogledu dorade ili vršenje usluge određenog proizvoda (ELEKTRO-STATIČKO FARBANJE, GRAVIRANJE, OBRADA CIJEVI, PROBIJANJE ILI SJECENJE PROFILA) </p>
                  <p className = "paragraf-proizvodi">Od početka proizvodnje  do danas značajno je podignut nivo opremljenosti mašinskog parka i uveliko povećan proizvodni pogon  i sa tim u vezi smo uvijek u potrazi za novim poslovnim partnerima kako u zemlji tako i u inostranstvu .</p>
                  <p className = "paragraf-proizvodi">Pored proizvodnje u mogućnosti smo ponuditi kupcima i poslovnim partnerima različite vidove saradnje kao sto su npr. dorada  nekog proizvoda ili poluproizvoda, usluge ili slično. </p>
                  </Col>
              </Row>
              <Row>
                <Col xl = {3}>
                  <p className = "info-paragraf"><span>MDM Stankom d.o.o.
                  Brčko Distrikt </span>
                  <br></br>ul. Bijeljinska 71
                  <br></br>Tel: 049/216-902 </p>
                  </Col>
                  <Col xl = {3} className = "offset-xl-1">
                  <p className = "info-paragraf"><span>PJ Bijeljina<br></br>
                  ul.Komitska bb </span>
                  <br></br>Tel: 055/210-080</p>
                  </Col>
              </Row>
          </Container>
          </div>
        </div>
        <div className="history-wrapper two">
          <Container>
            <Row>
               <Col xl="12" lg="12" md="12" sm="12">
                <Row>
                  <Col xl="2" lg="2" md="4" sm="6" xs="6">
                    <div className="services-box2">
                      <img src={worker} className="img-fluid mx-auto d-block icon3" />
                      <h5>EKSPERTI I PROFESIONALCI
                                    </h5>
                    </div>
                  </Col>
                  <Col xl="2" lg="2" md="4" sm="6" xs="6">
                    <div className="services-box2">
                      <img src={diamond} className="img-fluid mx-auto d-block icon4" />
                      <h5>PROFESIONALAN PRISTUP
                                    </h5>
                    </div>
                  </Col>
                  <Col xl="2" lg="2" md="4" sm="6" xs="6">
                    <div className="services-box2">
                      <img src={letter} className="img-fluid mx-auto d-block icon5" />
                      <h5>BRZ <br />ODGOVOR
                                    </h5>
                    </div>
                  </Col>
                  <Col xl="2" lg="2" md="4" sm="6" xs="6">
                    <div className="services-box2">
                      <img src={quality} className="img-fluid mx-auto d-block icon6" />
                      <h5>KVALITET <br /> U POSLU</h5>
                    </div>
                  </Col>
                  <Col xl="2" lg="2" md="4" sm="6" xs="6">
                    <div className="services-box2">
                      <img src={check} className="img-fluid mx-auto d-block icon7" />
                      <h5>GARANTOVANO ZADOVOLJSTO
                                    </h5>
                    </div>
                  </Col>
                  <Col xl="2" lg="2" md="4" sm="6" xs="6">
                    <div className="services-box2">
                      <img src={support} className="img-fluid mx-auto d-block icon8" />
                      <h5>POUZDANA <br />PODRŠKA
                                    </h5>
                    </div>
                  </Col>
                </Row>
              </Col>

            </Row>
          </Container>
        </div>
        <Map {...this.props}></Map>
        <Footer {...this.props}></Footer>
      </div>
    );
  }
}
