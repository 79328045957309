import React from 'react';
import BreadBack from './images/breadcrump-bg.png';
import { Link } from 'react-router-dom';
import Gallery from './Gallery';
import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink
} from 'reactstrap';
import Map from './Map';
import MyNavbar from './Navigation';
import Footer from './Footer';
export default class Photogallery extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidUpdate() {
    }

    render() {
        return (
            <div>
                <MyNavbar {...this.props}></MyNavbar>
                <div className="aboutUs">
                    <Container fluid>
                        <Row>
                            <img src={BreadBack} className="img-fluid" />
                        </Row>
                        <Container>
                            <Row>
                                <Col xl={6}>
                                    <h1>Galerija</h1>
                                </Col>
                                <Col xl={6} className="d-flex justify-content-end">
                                    <NavItem>
                                        <Link to="/" className="nav-link">Početna</Link>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#">Galerija</NavLink>
                                    </NavItem>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </div>
                <div>
                    <div className = "gallery-wrapper">
                    <div className = "gallery">
                    <Container>
                            <Col xl = {12}>
                         <h2>Foto galerija</h2>
                         <p>Naša firma je počela sa radom 1976.godine, kao SZR sa sjedištem u Bijeljini.</p>
                         </Col>
                         <Gallery {...this.props}></Gallery>
                    </Container>
                    </div>
                </div>
                </div>
                <Map {...this.props}></Map>
                <Footer {...this.props}></Footer>
            </div>
        );
    }
}
