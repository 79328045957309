import React, { Component } from 'react';
import MyNavbar from './Navigation';
import Footer from './Footer';
import Map from './Map';
import Productionslider from './Production-slider';
import Offerslider from './Offer-slider';
import Gallery from './Gallery';
import { Link } from 'react-router-dom';
import slide1 from './images/brcko.jpg';
import slide2 from './images/bijeljina.jpg';
import slide3 from './images/cijevi.jpg';
import slide4 from './images/limovi.jpg';
import slide5 from './images/profili.jpg';
import slide6 from './images/inox.jpg';
import slide7 from './images/lexan.jpg';
import slide8 from './images/background.png';

import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

import limovi from './images/limovi.png';
import ograde from './images/ograde.png';
import cijevi from './images/cijevi.png';
import icon1 from './images/icon1.png';
import icon2 from './images/icon2.png';
import picture1 from './images/picture1.png';
import picture2 from './images/picture2.png';
import picture3 from './images/picture3.png';
import picture4 from './images/picture4.png';
import picture5 from './images/picture5.png';
import picture6 from './images/picture6.png';
import picture7 from './images/picture7.png';
import picture8 from './images/picture8.png';
import worker from './images/workers.png';
import diamond from './images/diamond.png';
import letter from './images/letter.png';
import quality from './images/quality.png';
import check from './images/check.png';
import support from './images/support.png';
import production from './images/production.png';
import picture9 from './images/picture9.jpeg';
import close from './images/close.svg';
import novoBackground from './images/novoBackground.png';

const items = [
  {
    src: slide1,
    caption: 'Naše lokacije',
    header: 'Prodajno mjesto Brčko',
    text: 'Sjedište firme, ulica Bijeljinska 71, Brčko distrikt',
    btn: '/'
  },
  {
    src: slide2,
    caption: 'Naše lokacije',
    header: 'Podružnica Bijeljina',
    text: 'Ulica Komitska bb, Kružni tok kod FIS-a, Bijeljina.',
    btn: '/'
  },
  {
    src: slide3,
    caption: 'Izdvajamo iz ponude',
    header: 'Crna metalurgia',
    text: 'Crne kvadratne, pravougaone, okrugle, ZP profili, čelici',
    btn: '/ponuda/cijevi'
  },
  {
    src: slide4,
    caption: 'Izdvajamo iz ponude',
    header: 'Limovi',
    text: 'Hladnovaljani, toplovaljani, ravni, rebrasti, aluminijumski, inox.',
    btn: '/ponuda/limovi'
  },
  {
    src: slide5,
    caption: 'Izdvajamo iz ponude',
    header: 'Profili',
    text: 'Flahovi, firkanti, ugaonici, IPE, UPE, INP, UNP i HEA profili.',
    btn: '/ponuda/profili'
  },
  {
    src: slide6,
    caption: 'Izdvajamo iz ponude',
    header: 'Inox program',
    text: 'Okrugle, kvadratne i pravougaone cijevi, limovi i prateći elementi.',
    btn: '/ponuda/inox'
  },
  {
    src: slide7,
    caption: 'Izdvajamo iz ponude',
    header: 'Lexan',
    text: 'Polikarbonatne ploče svih dimenzija i boja, prateći program.',
    btn: '/ponuda/lexan'
  },
  {
    src: slide8,
    caption: 'Izdvajamo iz ponude',    
    header: 'Proizvodnja svih vrsta ograda',
    text: 'POC. i PVC kanati, POC i PVC kovani kanati, POC i PVC paneli, dvorišne i industrijske ograde.',
    btn: '/proizvodnja/kovani-kanati'
  },
];

const pictures = [
  {
    src: picture9,
    altText: 'Picture1',
    caption: 'Picture1'
  },
  {
    src: picture2,
    altText: 'Picture2',
    caption: 'Picture2'
  },
  {
    src: picture3,
    altText: 'Picture3',
    caption: 'Picture3'
  },
  {
    src: picture4,
    altText: 'Picture4',
    caption: 'Picture4'
  },
  {
    src: picture5,
    altText: 'Picture5',
    caption: 'Picture5'
  },
  {
    src: picture6,
    altText: 'Picture6',
    caption: 'Picture6'
  },
  {
    src: picture7,
    altText: 'Picture7',
    caption: 'Picture7'
  },
  {
    src: picture8,
    altText: 'Picture8',
    caption: 'Picture8'
  },
];
class App extends Component {
  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      activeIndex: 0
    };

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);

  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    window.scrollTo(0, 0);


  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }


  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }
  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }
  render() {

    const { activeIndex } = this.state;

    const slides = items.map((item) => {

      return (
        <CarouselItem className="bg"
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
        >
          <img src={item.src} className="img-fluid" alt={item.altText} />
          <div className="carousel-caption">

            <div className="container">
              <div className="row d-flex justify-content-start">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <h5>{item.caption}</h5>
                  <h1>{item.header}</h1>
                  <h6>{item.text}</h6>
                  <Link to={item.btn}><Button className="btn btn-danger">DETALJNIJE</Button></Link>
                </div>
              </div>
            </div>

          </div>
        </CarouselItem>
      )

    });


    return (

      <div>
        <MyNavbar {...this.props}></MyNavbar>
        <div className="wrapper">
          <Container fluid>
            <Row>
              <Carousel className="bg"
                activeIndex={activeIndex}
                next={this.next}
                previous={this.previous}
              >
                <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                {slides}
                <CarouselControl directionText="Previous" onClickHandler={this.previous} />
                <CarouselControl directionText="Next" onClickHandler={this.next} />
              </Carousel>

            </Row>
          </Container>
        </div>

        <div className="about">
          <Container>
            <Row>
              <Col xl="3" lg="3" md="6" sm="12" col="12">
                <img src={cijevi} className="img-fluid picture-width" />
                <div className="services-box">
                  <h3>CIJEVI</h3>
                  <p>Isporučuju se hladno oblikovane, okruglog, kvadratnog i pravougaonog presjeka.</p>
                  <Link to="ponuda/cijevi"><Button className="btn btn-danger">DETALJNIJE</Button></Link>

                </div>
              </Col>
              <Col xl="3" lg="3" md="6" sm="12" col="12">
                <img src={limovi} className="img-fluid picture-width" />
                <div className="services-box">
                  <h3>LIMOVI</h3>
                  <p>Primenu nalaze u raznim segmentima industrije i gra|evinarstva.</p>
                  <Link to="ponuda/limovi"><Button className="btn btn-danger">DETALJNIJE</Button></Link>
                </div>
              </Col>
              <Col xl="3" lg="3" md="6" sm="12" col="12">
                <img src={ograde} className="img-fluid picture-width" />
                <div className="services-box">
                  <h3>OGRADE</h3>
                  <p>Dizajn određuje kupac uz pomoć naše stručne službe zaposlenih i inžinjera.</p>
                  <Link to="proizvodnja/kovani-kanati"><Button className="btn btn-danger">DETALJNIJE</Button></Link>
                </div>
              </Col>
              <Col xl="3" lg="3" md="6" sm="12" xs="12">
                <div className="services-wrap">
                  <div className="services-box">
                    <h3>VLASTITA PROIZVODNJA</h3>
                    <p>U našim proizvodnim pogonima proizvodimo veliki broj raznih artikala.
                            </p>
                    <div className="row">
                      <Col xl="6" lg="6" md="6" sm="6" xs="6" className="divider2">
                        <img src={icon1} className="img-fluid icon1" />
                        <h4>250</h4>
                        <p>Artikala</p>
                      </Col>
                      <hr />
                      <Col xl="6" lg="6" md="6" sm="6" col="6" xs="6" className="divider3">
                        <img src={icon2} className="img-fluid icon2" />
                        <h4>35</h4>
                        <p>Radnika</p>
                      </Col>
                    </div>
                    <Link to="/about"><Button className="btn btn-danger">DETALJNIJE</Button></Link>

                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="history-wrapper">
          <Container>
            <Row>
              <Col xl="6" lg="6" md="6" sm="12">
                <div className="history">
                  <h6>O NAMA</h6>
                  <h1>Istorija kompanije</h1>
                  <p>
                    Naša firma je počela sa radom 1976.godine, kao SZR sa sjedištem u Bijeljini. Godine 1978. prenosimo sjedište u Republiku Sloveniju, tačnije u SO Tolmin, gdje smo radili sve do 1987.godine.<br /> Nakon toga smo prijavili sjedište
                                firme u Brčkom, gdje se i sada nalazimo, u ulici Bijeljinska br.71.</p>
                  <Link to="/about"><Button className="btn btn-danger">DETALJNIJE</Button></Link>
                </div>
              </Col>

              <Col xl="6" lg="6" md="6" sm="12">
                <Row>
                  <Col xl="4" lg="4" md="6" sm="6" xs="6">
                    <div className="services-box2">
                      <img src={worker} className="img-fluid mx-auto d-block icon3" />
                      <h5>EKSPERTI I PROFESIONALCI
                                    </h5>
                    </div>
                  </Col>
                  <Col xl="4" lg="4" md="6" sm="6" xs="6">
                    <div className="services-box2">
                      <img src={diamond} className="img-fluid mx-auto d-block icon4" />
                      <h5>PROFESIONALAN PRISTUP
                                    </h5>
                    </div>
                  </Col>
                  <Col xl="4" lg="4" md="6" sm="6" xs="6">
                    <div className="services-box2">
                      <img src={letter} className="img-fluid mx-auto d-block icon5" />
                      <h5>BRZ <br />ODGOVOR
                                    </h5>
                    </div>
                  </Col>
                  <Col xl="4" lg="4" md="6" sm="6" xs="6">
                    <div className="services-box2">
                      <img src={quality} className="img-fluid mx-auto d-block icon6" />
                      <h5>KVALITET <br /> U POSLU</h5>
                    </div>
                  </Col>
                  <Col xl="4" lg="4" md="6" sm="6" xs="6">
                    <div className="services-box2">
                      <img src={check} className="img-fluid mx-auto d-block icon7" />
                      <h5>GARANTOVANO ZADOVOLJSTO
                                    </h5>
                    </div>
                  </Col>
                  <Col xl="4" lg="4" md="6" sm="6" xs="6">
                    <div className="services-box2">
                      <img src={support} className="img-fluid mx-auto d-block icon8" />
                      <h5>POUZDANA <br />PODRŠKA
                                    </h5>
                    </div>
                  </Col>
                </Row>
              </Col>

            </Row>
          </Container>
        </div>

        <Offerslider></Offerslider>

        <Productionslider></Productionslider>

        <div className="new">
          <Container>
            <Row>
              <Col xl="6">
                <h3>NOVO U PONUDI</h3>
                <p>Usluzno rezanje CNC plazmom do 25mm.</p>
              </Col>
              <Col xl="3" className = "offset-xl-3">
                <Button>POGLEDAJTE DETALJNIJE</Button>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="gallery">
          <Container>
            <h3>Foto galerija</h3>
            <br></br>
            <p>Naša firma je počela sa radom 1976.godine, kao SZR sa sjedištem u Bijeljini.</p>
            <Gallery {...this.props} opened={this.state.opened}></Gallery>
            <Row className="d-flex justify-content-center">
              <Link to="/gallery#"><button className="btn btn-danger">KOMPLETNA PONUDA</button></Link>
            </Row>
          </Container>
        </div>


        <Map {...this.props}></Map>
        <Footer {...this.props}></Footer>
      </div>
    );
  }
}

export default App;
