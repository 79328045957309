import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

import {
  Container,
  Row,
  Button

} from 'reactstrap';

import limovi from './images/limovi.png';
import ograde from './images/ograde.png';
import cijevi from './images/cijevi.png';
import production from './images/production.png';
import kanati from './images/plastificirani-kanati.png';
import kanati2 from './images/kovani-panel.png';
import paneli from './images/plastificirani-paneli.png';
import pletivo from './images/grifovano-pletivo.png';
import kovani from './images/kovani-elementi.png';
import univerzalna from './images/univerzalna-pletiva.png';
import poklopci from './images/poklopci.png';
import farma from './images/oprema-farma.png';
import plastenik from './images/plastenik.png';
const items = [];

export default class Productionslider extends React.Component {
  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.state = {
    };

  }
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }


  render() {

    let settings = {
      slidesToShow: this.state.width <= 767 ? 1 : 4 && this.state.width <= 1024 ? 2 : 4,
      slidesToScroll: 1
    }

    return (
      <div>
        <div className="production">
          <Container fluid>
            <Row className="d-flex justify-content-center production-row">
              <img src={production} className="img-fluid production-bg"></img>
              <h2>Vlastita proizvodnja</h2>
              <h5>Naša firma je počela sa radom 1976.godine, kao SZR sa sjedištem u Bijeljini.</h5>
            </Row>
          </Container>

          <Container>
            <Row>
              <Slider {...settings}>
                <div>
                  <img src={kanati} className="img-fluid" />
                  <div className="services-box">
                    <h3>POCINČANI I PLASTIFICIRANI KANATI</h3>
                    <p> Dizajn i izgled Kanata odredjuje kupac uz pomoć zaposlenih i inžinjera.</p>
                    <Link to="/proizvodnja/kanati"><Button className="btn btn-danger">DETALJNIJE</Button></Link>
                  </div>
                </div>
                <div>
                  <img src={kanati2} className="img-fluid" />
                  <div className="services-box">
                    <h3>POCINČANI I PLASTIFICIRANI KOVANI KANATI</h3>
                    <p>Namijenjeni za ograđivanje poslovno-stambenih objekata, dvorišta, vjerskih objekata, itd.</p>
                    <Link to="/proizvodnja/kovani-kanati"><Button className="btn btn-danger">DETALJNIJE</Button></Link>
                  </div>
                </div>
                <div>
                  <img src={paneli} className="img-fluid" />
                  <div className="services-box">
                    <h3>POCINČANI I PLASTIFICIRANI PANELI</h3>
                    <p>Koriste se za ograđivanje individualnih stambenih, i poslovnih objekata.</p>
                    <Link to="/proizvodnja/paneli"><Button className="btn btn-danger">DETALJNIJE</Button></Link>
                  </div>
                </div>
                <div>
                  <img src={pletivo} className="img-fluid" />
                  <div className="services-box">
                    <h3>GRIFOVANA ILI TALASTASTA PLETIVA</h3>
                    <p>Namjenjeni za ograđivanje Dvorista, Poslovnih objekata, Teniskih terena, Škola, kao zaštita za prozore.</p>
                    <Link to="/proizvodnja/grifovana-pletiva"><Button className="btn btn-danger">DETALJNIJE</Button></Link>
                  </div>
                </div>
                <div>
                  <img src={kovani} className="img-fluid" />
                  <div className="services-box">
                    <h3>GRAVIRANI ELEMENTI ZA KOVANE OGRADE</h3>
                    <p>U ponudi imamo vise od 30 različitih šara, odnosno gravura. Koriste se za kovane ograde, stepeništa, balkone.</p>
                    <Link to="/proizvodnja/gravirano-zeljezo"><Button className="btn btn-danger">DETALJNIJE</Button></Link>
                  </div>
                </div>
                <div>
                  <img src={univerzalna} className="img-fluid" />
                  <div className="services-box">
                    <h3>UNIVERZALNA PLETIVA</h3>
                    <p>Univerzalna pletiva namjenjeno za ograđivanje bašta, voćnjaka, njiva, dvorišta, vinograda.</p>
                    <Link to="/proizvodnja/pvc-univerzalno-pletivo"><Button className="btn btn-danger">DETALJNIJE</Button></Link>
                  </div>
                </div>
                <div>
                  <img src={poklopci} className="img-fluid" />
                  <div className="services-box">
                    <h3>POKLOPCI ZA ŠAHTE</h3>
                    <p>Proizvod je toplo pocinkovan i proizvodi se u dvije serije, dostupan u dimenzijama po zahtjevu kupca.</p>
                    <Link to="/proizvodnja/poklopci-za-sahte"><Button className="btn btn-danger">DETALJNIJE</Button></Link>
                  </div>
                </div>
                <div>
                  <img src={farma} className="img-fluid" />
                  <div className="services-box">
                    <h3>OPREMA ZA FARME</h3>
                    <p>Proizvod je toplo pocinkovan i izrađen od okruglih profila.</p>
                    <Link to="/proizvodnja/prasilista"><Button className="btn btn-danger">DETALJNIJE</Button></Link>
                  </div>
                </div>
                <div>
                  <img src={plastenik} className="img-fluid" />
                  <div className="services-box">
                    <h3>CIJEVI ZA PLASTENIKE</h3>
                    <p>Ove cijevi su prvenstveno namijenjene za izgradnju konstrukcija za plastenike ali se koriste i u druge svrhe.</p>
                    <Link to="/proizvodnja/cijevi-za-plastenike"><Button className="btn btn-danger">DETALJNIJE</Button></Link>
                  </div>
                </div>
              </Slider>
            </Row>
          </Container>
        </div>
      </div>
    )
  }
};
