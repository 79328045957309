import React from 'react';
import { Link } from 'react-router-dom';
import { NavLink as RRNavLink } from 'react-router-dom';
import {
    Collapse,
    Container,
    Row,
    Col,
    Navbar,
    NavbarBrand,
    DropdownToggle,
    DropdownMenu,
    NavItem,
    NavLink,
    Nav, UncontrolledDropdown,
} from 'reactstrap';
import email from './images/email.png';
import phone from './images/phone.png';
import location from './images/location.png';
import fb from './images/fb.png';
import instagram from './images/instagram.png';
import logo1 from './images/logo-1.svg';

export default class MyNavbar extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };

    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.pathname !== this.props[0].location.pathname) {
            this.setState({
                isOpen: null
            })
        }
    }

    render() {
        console.log(this.props.siteData);

        return (
            <div>

                <div className="header-container">
                    <div className="header-top">
                        <Container>
                            <Row>
                                <Col xl="3" lg="3">
                                    <p><img src={email} className="img-fluid email" />{this.props.siteData ? this.props.siteData.email : ''}</p>
                                </Col>
                                <Col xl="4" lg="3" className="d-flex justify-content-start">
                                    <p className="p-secound"><img src={phone} className="img-fluid phone" />{this.props.siteData ? this.props.siteData.telephone.split('|')[0] : ''}&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;{this.props.siteData ? this.props.siteData.telephone.split('|')[1] : ''}</p>
                                </Col>
                                <Col xl="5" lg="6" className="d-flex justify-content-end">
                                    <p><img src={location} className="img-fluid location" />{this.props.siteData ? this.props.siteData.address.split('|')[0] : ''}&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;{this.props.siteData ? this.props.siteData.address.split('|')[1] : ''}
                                        <a href="https://www.facebook.com/InoxCrnaMetalurgija/"><img src={fb} className="img-fluid fb" /></a>
                                        <img src={instagram} className="img-fluid instagram" />
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <Navbar className="navbar navbar-expand-lg static-top">
                    <Container>
                        <NavbarBrand href="/">
                            <img src={logo1} alt="" className="img-fluid logo" />
                        </NavbarBrand>
                        <button className="btn-menu" onClick={() => this.setState({ isOpen: !this.state.isOpen })}><i className="fa fa-align-justify"></i></button>
                        <Collapse isOpen={this.state.isOpen} navbar id="navbarResponsive">
                            <Nav className="ml-auto" navbar>
                                <NavItem className="nav-item">
                                    <NavLink exact tag={RRNavLink} className="nav-link" to="/" activeClassName="active">Početna
                                </NavLink>
                                </NavItem>
                                <NavItem className="nav-item">
                                    <NavLink tag={RRNavLink} className="nav-link" to="/about" activeClassName="active">O nama</NavLink>
                                </NavItem>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret href="">Ponuda<i className="fa fa-angle-down"></i></DropdownToggle>
                                    <DropdownMenu right>
                                        <Link to="/ponuda/cijevi" className="dropdown-item">Cijevi</Link>
                                        <hr></hr>
                                        <Link to="/ponuda/limovi" className="dropdown-item">Limovi</Link>
                                        <hr></hr>
                                        <Link to="/ponuda/profili" className="dropdown-item">Profili</Link>
                                        <hr></hr>
                                        <Link to="/ponuda/zeljezo" className="dropdown-item">Željezo</Link>
                                        <hr></hr>
                                        <Link to="/ponuda/ugaonici" className="dropdown-item">Ugaonici</Link>
                                        <hr></hr>
                                        <Link to="/ponuda/gradjevinski-materijal" className="dropdown-item">Građevinski materijal</Link>
                                        <hr></hr>
                                        <Link to="/ponuda/mreze" className="dropdown-item">Mreže</Link>
                                        <hr></hr>
                                        <Link to="/ponuda/lexan" className="dropdown-item">Lexan</Link>
                                        <hr></hr>
                                        <Link to="/ponuda/inox-program" className="dropdown-item">Inox program</Link>
                                        <hr></hr>
                                        <Link to="/ponuda/kovani-elementi" className="dropdown-item">Kovani elementi</Link>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret href="" >Proizvodnja<i className="fa fa-angle-down"></i></DropdownToggle>
                                    <DropdownMenu right>
                                        <Link to="#" className="dropdown-item" >Proizvodi od žičanih pletiva <i className="fa fa-angle-right"></i>
                                            <ul className="submenu">
                                                <NavItem className="nav-item">
                                                    <Link to="/proizvodnja/pvc-univerzalno-pletivo" className="dropdown-item">PVC univerzalno pletivo</Link>
                                                </NavItem>
                                                <hr></hr>
                                                <NavItem className="nav-item">
                                                    <Link to="/proizvodnja/pocincano-pletivo" className="dropdown-item">Pocinčano pletivo</Link>
                                                </NavItem>
                                                <hr></hr>
                                                <NavItem className="nav-item">
                                                    <Link to="#" className="dropdown-item">Prateći program<i className="fa fa-angle-right"></i>
                                                        <ul className="submenu3">
                                                            <NavItem className="nav-item">
                                                                <Link to="/proizvodnja/pvc-zatezna-zica" className="dropdown-item">PVC zatezna žica</Link>
                                                            </NavItem>
                                                            <hr></hr>
                                                            <NavItem className="nav-item">
                                                                <Link to="/proizvodnja/spaneri-ili-zatezaci" className="dropdown-item">Španeri ili zatezači</Link>
                                                            </NavItem>
                                                            <hr></hr>
                                                            <NavItem className="nav-item">
                                                                <Link to="/proizvodnja/pvc-stubovi-za-univezalno-pletivo" className="dropdown-item">PVC stubovi za univezalno pletivo</Link>
                                                            </NavItem>
                                                        </ul>
                                                    </Link>
                                                </NavItem>
                                            </ul>
                                        </Link>
                                        <hr />
                                        <Link to="/proizvodnja/grifovana-pletiva" className="dropdown-item">Grifovana pletiva</Link>
                                        <hr></hr>
                                        <Link to="/proizvodnja/kanati" className="dropdown-item">Kanati</Link>
                                        <hr></hr>
                                        <Link to="/proizvodnja/kovani-kanati" className="dropdown-item">Kovani kanati</Link>
                                        <hr></hr>
                                        <Link to="/proizvodnja/paneli" className="dropdown-item">Paneli</Link>
                                        <hr></hr>
                                        <Link to="/proizvodnja/gravirano-zeljezo" className="dropdown-item">Gravirano zeljezo</Link>
                                        <hr></hr>
                                        <Link to="#" className="dropdown-item">Oprema za svinjske farme<i className="fa fa-angle-right"></i>
                                            <ul className="submenu2">
                                                <NavItem className="nav-item">
                                                    <Link to="/proizvodnja/prasilista" className="dropdown-item">Prasilišta</Link>
                                                </NavItem>
                                                <hr></hr>
                                                <NavItem className="nav-item">
                                                    <Link to="/proizvodnja/pripustilista" className="dropdown-item">Pripustilišta</Link>
                                                </NavItem>
                                            </ul>
                                        </Link>
                                        <hr></hr>
                                        <Link to="/proizvodnja/poklopci-za-sahte" className="dropdown-item">Poklopci za šahte</Link>
                                        <hr></hr>
                                        <Link to="/proizvodnja/cijevi-za-plastenike" className="dropdown-item">Cijevi za plastenike</Link>
                                        <hr></hr>                                                                        
                                        <Link to="/proizvodnja/plastifikacija" className="dropdown-item">Plastifikacija</Link>
                                        <hr></hr>
                                        <Link to="/proizvodnja/double-paneli" className="dropdown-item">Double paneli</Link>
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                                <NavItem>
                                    <NavLink tag={RRNavLink} className="nav-link" to="/gallery" activeClassName="active">Galerija</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={RRNavLink} className="nav-link" to="/contact" activeClassName="active">Kontakt</NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
            </div>

        );
    }
}
