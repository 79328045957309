import React from 'react';
import BreadBack from './images/breadcrump-bg.png';
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Button,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap';
import email from './images/email.png';
import Map from './Map';
import MyNavbar from './Navigation';
import Footer from './Footer';
export default class Production extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

    }
    componentDidUpdate() {
    }

    render() {
        return (
            <div>
                <MyNavbar {...this.props}></MyNavbar>
                <div className="contact">
                    <Container fluid>
                        <Row>
                            <img src={BreadBack} className="img-fluid" />
                        </Row>
                        <Container>
                            <Row>
                                <Col xl={6}>
                                    <h1>Kontakt</h1>
                                </Col>
                                <Col xl={6} className="d-flex justify-content-end">
                                    <NavItem>
                                        <Link to="/" className="nav-link">Početna</Link>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#">Kontakt</NavLink>
                                    </NavItem>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                    <div className="contactText">
                        <Container>
                            <Row>
                                <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <h2>Kontaktirajte nas</h2>
                                    <p>NAŠE POSLOVNICE U BRČKOM I BIJELJINI</p>
                                </Col>

                            </Row>
                            <Row>
                                <Col xl={6} lg={6} md={6}>
                                    <p className="info-paragraf"><span>Brčko Distrikt</span>
                                        <br />ul. Bijeljinska 71 &nbsp;&nbsp;&nbsp;&nbsp; Tel: 049/216-902 </p>
                                </Col>
                                <Col xl={6} lg={6} md={6}>
                                    <p className="info-paragraf"><span>PJ Bijeljina</span><br></br>
                                        ul.Komitska bb&nbsp;&nbsp;&nbsp;&nbsp;
                                        Tel: 055/210-080</p>
                                </Col>
                            </Row>

                        </Container>
                    </div>

                    <div className="contact-form">
                        <Container>
                            <Row>
                                <Col xl={12}>
                                    <h6>KONTAKTIRAJTE NAS</h6>
                                </Col>
                            </Row>
                            <Form>
                                <Row>
                                    <Col xl={8} lg={8} md={12} sm={12} xs={12}>
                                        <Row>
                                            <Col xl={6} lg={6} md={6}>
                                                <FormGroup>
                                                    <Input type="text" name="Ime" id="telefon" placeholder="Ime" />
                                                    <Label for="ime">VAŠE IME</Label>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Input type="text" name="telefon" id="telefon" placeholder="Telefon" />
                                                    <Label for="telefon">VAŠ BROJ TELEFONA</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={6} lg={6} md={6}>
                                                <FormGroup>
                                                    <Input type="text" name="prezime" id="prezime" placeholder="Prezime" />
                                                    <Label for="ime">VAŠE PREZIME</Label>
                                                </FormGroup>
                                                <FormGroup>
                                                    <Input type="email" name="email" id="email" placeholder="Email" />
                                                    <Label for="email">VAŠA E-MAIL ADRESA</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <FormGroup>
                                                    <Input type="text" name="subject" id="subject" placeholder="Naslov" />
                                                    <Label for="subject">SUBJECT</Label>

                                                </FormGroup>
                                            </Col>
                                            <Col xl={12} lg={12} md={12}>
                                                <FormGroup>
                                                    <Input type="textarea" placeholder=" Vaša poruka" />
                                                    <Label for="text">PORUKA</Label>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Button className="btn btn-send">POŠALJI</Button>

                                    </Col>
                                    <Col xl={4} lg={4} md={12}>
                                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                                            <div className="contact-card">
                                                <h2>INFORMACIJE</h2>
                                                <p>Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku.</p>
                                                <div className="mid-text">
                                                    <p><span>MDM-STANKOM d.o.o.</span></p>
                                                    <p><img src={email} className="img-fluid email"></img>&nbsp;&nbsp;&nbsp;mdm-stankom@teol.net</p>
                                                    <p className="secound">info@mdmstankom.com</p>
                                                </div>
                                                <div className="workHours">
                                                    <h3>RADNO VRIJEME</h3>
                                                    <Row>
                                                        <Col xl="6" lg = "6" md = "6" sm = "6" xs = "6">
                                                            <p><span className = "time">Zima</span></p>
                                                                <p><span>Ponedeljak-Petak</span></p>
                                                                <p>07:00 - 16:00h</p>
                                                                <br></br>
                                                                <p><span>Subota</span></p>
                                                                <p>07:00 - 16:00h</p>
                                                        </Col>
                                                        <Col xl="6" lg = "6" md = "6" sm = "6" xs = "6">
                                                                <p className = "time"><span>Ljeto</span></p>
                                                                <p><span>Ponedeljak-Petak</span></p>
                                                                <p>07:00 - 17:00h</p>
                                                                <br></br>
                                                                <p><span>Subota</span></p>
                                                                <p>07:00 - 16:00h</p>

                                                        </Col>
                                                        <Col xl = "12" lg = "12" md = "12" sm = "12" xs = "12">
                                                        <br></br>
                                                        <p><span>Nedeljom ne radimo</span></p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                    </Col>
                                </Row>

                            </Form>
                        </Container>
                    </div>
                </div>

                <Map {...this.props}></Map>
                <Footer {...this.props}></Footer>
            </div>
        );
    }
}
