import React from 'react';
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink
} from 'reactstrap';
import email from './images/email.png';
import phone from './images/phone.png';
import location from './images/location.png';
import logo1 from './images/logo-1.svg';


export default class Footer extends React.Component {
    render() {
        return (
            <div>
                <div className="footer">
                    <Container>
                        <Row>
                            <Col xl={4} lg={4} md={6}>
                                <img src={logo1} className="img-fluid logo"></img>
                                <p>Naša firma je počela sa radom 1976.godine,
                                kao SZR sa sjedištem u Bijeljini.
                                Godine 1978. prenosimo sjedište u
                                Republiku Sloveniju, tačnije u SO Tolmin,
              gdje smo radili sve do 1987.godine.</p>
                            </Col>

                            <Col xl={4} lg={4} md={6}>
                                <h6>NAVIGACIJA</h6>
                                <hr></hr>
                                <Row>
                                    <Col xl={6} lg={6} md={6}>
                                        <NavItem>
                                            <Link to="/" className="nav-link">Početna</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to="/about" className="nav-link">O nama</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to="/production" className="nav-link">Proizvodnja</Link>
                                        </NavItem>
                                    </Col>
                                    <Col xl={6} lg={6} md={6}>
                                        <NavItem>
                                            <Link to="/offer" className="nav-link">Ponuda</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to="/gallery" className="nav-link">Galerija</Link>
                                        </NavItem>
                                        <NavItem>
                                            <Link to="/contact" className="nav-link">Kontakt</Link>
                                        </NavItem>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl={4} lg={4} md={6}>
                                <h6>KONTAKT</h6>
                                <hr></hr>
                                <NavItem>
                                    <NavLink href="#"><div className="footer-divider"><img src={location} className="img fluid location"></img></div>{this.props.siteData ? this.props.siteData.address.split('|')[0] : ''}<br />
                                    {this.props.siteData ? this.props.siteData.address.split('|')[1] : ''}</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#"><div className="footer-divider"><img src={phone} className="img-fluid phone"></img></div>{this.props.siteData ? this.props.siteData.telephone.split('|')[0] : ''}&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;{this.props.siteData ? this.props.siteData.telephone.split('|')[1] : ''}</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#"><div className="footer-divider"><img src={email} className="img-fluid email"></img></div>{this.props.siteData ? this.props.siteData.email : ''}</NavLink>
                                </NavItem>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="copyright">
                    <Container>
                        <Row>
                            <Col xl="5" lg="6" md="8" sm="12" xs="12">
                                <p>Copyright © MDM Stankom  - 2019. All Rights Reserved.</p>
                            </Col>
                            <Col xl="3" lg="3" md="4" sm="12" xs="12" className="offset-xl-4 offset-lg-3 d-flex justify-content-xl-end justify-content-sm-start">
                                <p>Created by <span><a href="https://www.novamedia.agency/">NOVA media</a></span></p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

        )
    }
};