import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
    Image,
    Button,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators
} from 'reactstrap';


import picture1 from './images/picture1.png';
import picture2 from './images/picture2.png';
import picture3 from './images/picture3.png';
import picture4 from './images/picture4.png';
import picture5 from './images/picture5.png';
import picture6 from './images/picture6.png';
import picture7 from './images/picture7.png';
import picture8 from './images/picture8.png';
import picture9 from './images/picture9.jpeg';
export default class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            gallery: []
        };
        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

    }


    
    componentDidUpdate(prevProps)
    {
        if (prevProps.opened != this.props.opened) {
            this.setState({opened: this.props.opened});
        }

        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

        


    }

    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });



        this.props.socketIOClient.on('fetchGallery', (data) => {
            console.log(data);
            this.setState({
                gallery: data
            });
        });

        this.props.socketIOClient.emit('fetchGallery', {home: this.props[0].location.pathname === '/gallery' ? null : true});




    }

    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("fetchGallery");
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.gallery.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.gallery.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }
    render() {
        let activeIndex = this.state.activeIndex;
        const slides2 = this.state.gallery.map((item, idx) => {
            return (
                <CarouselItem className="bg"
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={idx}>
                    <img src={'https://mdmstankom.com/' + item.image} className="img-fluid" alt={'Galerija ' + idx} />
                    <div className="carousel-caption">

                        <div className="container">
                            <div className="row d-flex justify-content-start">
                                <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12">
                                </div>
                            </div>
                        </div>

                    </div>
                </CarouselItem>
            )
        });


        return (
            <Row>
                {
                    this.state.gallery.map((item, idx) => {
                        return (
                            <Col xl={3} lg={4} md={6} sm={12} key={idx}>
                            <img src={'https://mdmstankom.com/' + item.image} className="img-fluid pic" onClick={() => this.setState({ opened: true, activeIndex: idx })} />
                        </Col>
        
                        )
                    })
                }

                {this.state.opened ?
                    <div className="lightbox">
                        <Container>
                            <Row>
                            <i className="fa fa-times" aria-hidden="true" onClick={() => this.setState({ opened: false })}></i>

                                <Carousel className="bg"
                                    activeIndex={activeIndex}
                                    next={this.next}
                                    previous={this.previous}
                                >
                                    <CarouselIndicators items={this.state.gallery} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                                    {slides2}
                                    <i className="fa fa-angle-left" onClick={this.previous}></i>
                                    <i className="fa fa-angle-right" onClick={this.next}></i>

                                </Carousel>
                            </Row>
                        </Container>
                    </div> : null
                }
            </Row>
        )
    }
};

