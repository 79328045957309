import React from 'react';
import Slider from 'react-slick';
import {Link} from 'react-router-dom';
import {
    Container,
    Row,
    Button, Col

} from 'reactstrap';

import limovi from './images/limovi.png';
import ograde from './images/ograde.png';
import cijevi from './images/cijevi.png';
import inox from './images/inox-img.png';
import lexan from './images/lexan-img.png';
import pocincane from './images/pocincane-ograde.png';
import profili from './images/alu-profili.png';
import kovani from './images/kovani-elementi.png';
import pletiva from './images/pletiva.png';
import pocincani from './images/pocincani-paneli.png';

const items = [];

export default class Offerslider extends React.Component {
    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.state = {
        };

    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


    render() {
        let settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: this.state.width <= 767 ? 1 : 4 && this.state.width <= 1024 ? 2 : 4,
            slidesToScroll: 1
          }

        return (
            <div>
                <div className="offer">
                    <Container>
                        <Row className="d-flex justify-content-between">
                            <Col xl="5">
                                <h6>ČIME SE BAVIMO</h6>
                                <h1>Izdvajamo iz ponude</h1>
                            </Col>
                            <Col xl="4">
                                <p>Naša firma je počela sa radom 1976.godine, kao SZR sa sjedištem u Bijeljini.</p>
                            </Col>
                           
                        </Row>

                        <Row>
                            <Slider {...settings}>
                            <div>
                                    <img src={cijevi} className="img-fluid" />
                                    <div className="services-box">
                                        <h3>CIJEVI</h3>
                                        <p>U ponudi širok asortiman cijevi dostupnih u više oblika i dimenzija.</p>
                                        <Link to = "/ponuda/cijevi"><Button className="btn btn-danger">DETALJNIJE</Button></Link>
                                    </div>
                                </div>
                                <div>
                                    <img src={limovi} className="img-fluid" />
                                    <div className="services-box">
                                        <h3>LIMOVI</h3>
                                        <p>U ponudi širok asortiman limova za primjeru u različitim segmentima.</p>
                                        <Link to = "/ponuda/limovi"><Button className="btn btn-danger">DETALJNIJE</Button></Link>

                                    </div>
                                </div>
                                <div>
                                    <img src={profili} className="img-fluid" />
                                    <div className="services-box">
                                        <h3>PROFILI</h3>
                                        <p>Profili namjenjeni za izgradnju različitih skolopa u industriji i građevinarstvu.</p>
                                        <Link to = "/ponuda/profili"><Button className="btn btn-danger">DETALJNIJE</Button></Link>
                                    </div>
                                </div>
                           
                                <div>
                                    <img src={inox} className="img-fluid" />
                                    <div className="services-box">
                                        <h3>INOX PROGRAM</h3>
                                        <p>Inox program sadrži više vrsta lajsni, različite vrste ograda i nadstrešnica, rukohvate...</p>
                                        <Link to = "/ponuda/inox-program"><Button className="btn btn-danger">DETALJNIJE</Button></Link>

                                    </div>
                                </div>
                                <div>
                                    <img src={lexan} className="img-fluid" />
                                    <div className="services-box">
                                        <h3>LEXAN</h3>
                                        <p>Vroma širok spektar primjene sa čvrstim materijalom i UV zaštitom.</p>
                                        <Link to = "/ponuda/lexan"><Button className="btn btn-danger">DETALJNIJE</Button></Link>
                                    </div>
                                </div>
                                <div>
                                    <img src={pocincane} className="img-fluid" />
                                    <div className="services-box">
                                        <h3>POCINCANE I PLASIFICIRANE OGRADE</h3>
                                        <p>Za ograđivanje objekata.</p>
                                        <Link to = "/proizvodnja/kovani-kanati"><Button className="btn btn-danger">DETALJNIJE</Button></Link>
                                    </div>
                                </div>
                                <div>
                                    <img src={kovani} className="img-fluid" />
                                    <div className="services-box">
                                        <h3>GRAVIRANI ELEMENTI ZA KOVANE OGRADE</h3>
                                        <p>Namjenjeni su za ograde i kovane ukrase.</p>
                                        <Link to = "/ponuda/kovani-elementi"><Button className="btn btn-danger">DETALJNIJE</Button></Link>
                                    </div>
                                </div>
                                <div>
                                    <img src={pletiva} className="img-fluid" />
                                    <div className="services-box">
                                        <h3>GRIFOVANA PLETIVA</h3>
                                        <p>Namjenjena za ograđivanje objekata, sa veoma velikim stepenom čvrstoće i sigurnosti.</p>
                                        <Link to = "/proizvodnja/grifovana-pletiva"><Button className="btn btn-danger">DETALJNIJE</Button></Link>
                                    </div>
                                </div>
                                <div>
                                    <img src={pocincani} className="img-fluid" />
                                    <div className="services-box">
                                        <h3>POCINCANI I PLASTIFICIRANI PANELI</h3>
                                        <p>Paneli sa površinskom zaštitom.</p>
                                        <Link to = "/proizvodnja/paneli"><Button className="btn btn-danger">DETALJNIJE</Button></Link>
                                    </div>
                                </div>
                            </Slider>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
};
